import { ref, watch, computed } from "@vue/composition-api";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default function useCityList() {
  // Use toast
  const toast = useToast();
  const { t } = useI18nUtils();

  const refCityListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id" },
    { key: "name", label: t("Name") },
    { key: "active", label: t("Is active") },
    { key: "actions", label: t("Actions") },
  ];
  const perPage = ref(10);
  const totalCity = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const nameRef = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refCityListTable.value
      ? refCityListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCity.value,
    };
  });

  const refetchData = () => {
    refCityListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, nameRef], () => {
    refetchData();
  });

  const fetchCity = (ctx, callback) => {
    instance
      .get("/parameters/cities/", {
        params: {
          page: currentPage.value,
          name: nameRef.value,
        },
      })
      .then((response) => {
        const { count, results } = response.data;
        totalCity.value = count;
        callback(results);
      })
      .catch((err) => {

        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;

        toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: error ?? "An error has occurred. Please try again",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchCity,
    tableColumns,
    perPage,
    currentPage,
    totalCity,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCityListTable,
    refetchData,
    nameRef,
  };
}
